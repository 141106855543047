/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.3.1) components

@import "argon-dashboard/bootstrap/root";
@import "argon-dashboard/bootstrap/reboot";
@import "argon-dashboard/bootstrap/type";
@import "argon-dashboard/bootstrap/images";
@import "argon-dashboard/bootstrap/code";
@import "argon-dashboard/bootstrap/grid";
@import "argon-dashboard/bootstrap/tables";
@import "argon-dashboard/bootstrap/forms";
@import "argon-dashboard/bootstrap/buttons";
@import "argon-dashboard/bootstrap/transitions";
@import "argon-dashboard/bootstrap/dropdown";
@import "argon-dashboard/bootstrap/button-group";
@import "argon-dashboard/bootstrap/input-group";
@import "argon-dashboard/bootstrap/custom-forms";
@import "argon-dashboard/bootstrap/nav";
@import "argon-dashboard/bootstrap/navbar";
@import "argon-dashboard/bootstrap/card";
@import "argon-dashboard/bootstrap/breadcrumb";
@import "argon-dashboard/bootstrap/pagination";
@import "argon-dashboard/bootstrap/badge";
@import "argon-dashboard/bootstrap/jumbotron";
@import "argon-dashboard/bootstrap/alert";
@import "argon-dashboard/bootstrap/progress";
@import "argon-dashboard/bootstrap/media";
@import "argon-dashboard/bootstrap/list-group";
@import "argon-dashboard/bootstrap/close";
@import "argon-dashboard/bootstrap/modal";
@import "argon-dashboard/bootstrap/tooltip";
@import "argon-dashboard/bootstrap/popover";
@import "argon-dashboard/bootstrap/carousel";
@import "argon-dashboard/bootstrap/utilities";
@import "argon-dashboard/bootstrap/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";

.short-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 55px;
  margin-top: -1px;
}
.dropdown-user {
  display: flex;
  align-items: flex-start;
  background: #1175ef;
  padding: 7px 10px;
  border-radius: 50px;
}
.dropdown-user .avatar {
  color: #1175ef;
  background-color: #ffffff;
}
@media only screen and (max-width: 991px) {
  .dropdown-user {
    background: transparent;
  }
  .dropdown-user .avatar {
    background-color: #f2f4f9;
  }
  .select-res {
    width: 120px !important;
  }
  .tables .header-info .tabs {
    width: 100% !important;
  }
  .tables .header-info {
    align-items: unset!important;
      flex-direction: column!important;
  }
  .tables .date {
    padding: 0px 24px;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand {
    padding-bottom: 0rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 3rem;
    margin-top: 1.5rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog.tables {
    max-width: 85% !important;
  }
}

.modal-dialog.tables .modal-body {
  overflow: scroll;
  height: 75vh;
}
.tables .header-info {
  display: flex;
  justify-content: start;
  align-items: center;
}
.tables .header-info .tabs {
  width: 60%;
  margin-left: 24px;
}
.tables .header-info .tabs .restaurants h2 {
  font-size: 24px;
  padding: 20px 0;
}
.tables .header-info .tabs .restaurants a,
.tables .header-info .tabs .reservations a {
  padding: 10px 30px;
  background: #ededed;
  margin-right: 20px;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.4s ease;
  display: flex;
    justify-content: center;
    align-items: center;
}

.tables .header-info .tabs .restaurants a.active,
.tables .header-info .tabs .restaurants a:hover,
.tables .header-info .tabs .reservations a.active,
.tables .header-info .tabs .reservations a:hover {
  color: #fff;
  background: #5e72e4;
}

.singleTable {
  width: 100px;
  height: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4fd69c;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}
.singleTable p {
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 8px 0 0 0;
}
.singleTable span.add {
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  margin-bottom: -10px;
}
.singleTable span.listTable {
  text-align: center;
  font-size: 17px;
  display: block;
  cursor: pointer;
}

.singleTable span.delete {
  margin-left: calc(50% - 30px);
  margin-right: 10px;
  font-size: 25px;
  cursor: pointer;
}
.singleTable span.confirm {
  font-size: 22px;
  cursor: pointer;
}

.singleTable.reserved {
  background-color: #fbd746;
}
.singleTable.confirmed {
  background-color: #e22626;
}
.singleTable.circle {
  border-radius: 50%;
}
.singleTable.noshape {
  border: none;
  font-size: 30px;
  background-color: unset;
  box-shadow: none;
}
.singleTable.noshape p {
  font-size: 50px;
  margin-top: 0px;
}
.singleTable.noshape span {
  display: none;
}

.singleTable.line {
  border: none;
  background-color: #444040;
  box-shadow: none;
  width: 15px;
  height: 300px;
  z-index: 3;
}

.singleTable.line p,
.singleTable.line span {
  display: none;
}

.cardHeader.reserved {
  border-left: 20px solid #fbd746;
}
.cardHeader.confirmed {
  border-left: 20px solid #e22626;
}

div#shanku {
  margin-left: 70px;
}

.absoluteRight {
  position: absolute;
  right: 0;
  z-index: 99;
}